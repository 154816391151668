import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

import {
  authUser,
  resetPassword,
  resetPasswordConfirm,
  getResetPasswordData,
  emailConfirm,
  getEmailConfirmationData,
  sendFeedback,
  getAnonMarket,
  createUser
} from '@anonymous/services'

import {
  baseActions,
  baseGetters,
  baseMutations,
  baseState,
  setToken,
  anonRequestInterceptor
} from '@base/store'

import { actionTypes, mutationTypes } from '@base/store/store-types'

Vue.use(Vuex)

const axiosInstances = [
  authUser,
  resetPassword,
  resetPasswordConfirm,
  getResetPasswordData,
  emailConfirm,
  getEmailConfirmationData,
  sendFeedback,
  getAnonMarket,
  createUser
]

for (const instance of axiosInstances) {
  instance.interceptors.request.use(anonRequestInterceptor)
}

const initialState = {
  ...baseState,
  resetPasswordData: {
    validLink: null,
    uid: null,
    token: null,
    user: null,
    email: null,
  },
  emailConfirmationData: {
    validLink: null,
    emailVerified: null,
    user: null,
    email: null,
    uid: null,
    token: null
  },
  anonMarket: null,
}

document.initialState = initialState      // Need in each section stores for base logout user behavior

export default new Vuex.Store({
  state: initialState,
  getters: {
    ...baseGetters,

  },
  mutations: {
    ...baseMutations,
    [mutationTypes.SET_RESET_PASSWORD_DATA](state, data) {
      state.resetPasswordData = data
    },
    [mutationTypes.SET_CONFIRMATION_DATA](state, data) {
      state.emailConfirmationData = data
    },
    [mutationTypes.SET_ANON_MARKET](state, market) {
      state.anonMarket = market
    },
  },
  actions: {
    ...baseActions,


    // eslint-disable-next-line no-unused-vars
    [actionTypes.LOGIN_USER]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        authUser({
          data: payloads
        })
          .then(response => {
            response.data.date = new Date()
            const camelCaseToken = _.mapKeys(response.data, (v, k) => _.camelCase(k))
            setToken(camelCaseToken)
            resolve(camelCaseToken)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.RESET_PASSWORD]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        resetPassword({
          data: payloads
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.CONFIRM_RESET_PASSWORD]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        resetPasswordConfirm({
          url: `${payloads.uid}/${payloads.token}/`,
          data: payloads
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.GET_RESET_PASSWORD_DATA]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        getResetPasswordData({
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.SET_RESET_PASSWORD_DATA, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.GET_CONFIRMATION_DATA]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        getEmailConfirmationData({
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.SET_CONFIRMATION_DATA, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.CONFIRM_EMAIL]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        emailConfirm({
          data: payloads
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.SEND_FEEDBACK]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        sendFeedback({
          data: payloads
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.GET_ANON_MARKET]({ commit }, marketId) {
      return new Promise((resolve, reject) => {
        getAnonMarket({
          url: `${marketId}/`
        })
          .then(response => {
            if (response.data.marketId) {
              commit(mutationTypes.SET_ANON_MARKET, response.data)
              resolve(response.data)
            }
            reject(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.CREATE_USER]({ dispatch }, payloads) {
      return new Promise((resolve, reject) => {
        createUser({
          data: payloads
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
  }
})
