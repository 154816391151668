<template>
  <div class="form-box-wrap">
    <b-container>
      <div v-if="isGlobalApp" class="d-flex justify-content-center mt-2 mb-2">
        <b-img width="150" height="130" :src="require('@base/assets/images/flealover-sitelogo.png')" />
      </div>
      <div v-else class="d-flex justify-content-center">
        <b-img :src="require('@base/assets/images/sitelogo.png')" />
      </div>
      <b-row class="justify-content-center"><h3>{{ $t('pages.email_confirm.title') }}</h3></b-row>
      <div v-if="showForm">
        <b-row class="justify-content-center">
          <div v-if="emailConfirmationData.validLink && !emailConfirmationData.emailVerified">
            <p
              class="text-center"
              v-html="$t('pages.email_confirm.confirmation_text', { email: emailConfirmationData.email })">
            </p>
            <div class="form-actions">
              <b-button
                @click="verifyEmail"
                variant="primary">
                {{ $t('pages.email_confirm.button_verify') }}
              </b-button>
            </div>
          </div>
          <div class="text-center text-secondary" v-else-if="emailConfirmationData.validLink && emailConfirmationData.emailVerified">
            <h3>
              {{ $t('pages.email_confirm.already_confirmed', {
                username: emailConfirmationData.user,
                email: emailConfirmationData.email
              }) }}
            </h3>
          </div>
          <div v-else class="text-center text-secondary">
            <h3>
              {{ $t('pages.email_confirm.invalid_link') }}
            </h3>
          </div>
        </b-row>
      </div>
      <div v-else>
        <b-row class="justify-content-center">
          <h3>
            {{ $t('pages.email_confirm.email_verified', { countDown: countDown }) }}
          </h3>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import countDownTimerMixin from '@base/mixins/CountDownTimerMixin.vue'
  import { REDIRECT_COUNTDOWN_SECONDS, IS_GLOBAL_APP } from '@base/configs'

  export default {
    name: 'AdminEmailConfirm',

    mixins: [ countDownTimerMixin ],

    data () {
      return {
        showForm: true,
        countDown: REDIRECT_COUNTDOWN_SECONDS,
        isGlobalApp: IS_GLOBAL_APP,
      }
    },

    created () {
      this.$store.dispatch(this.$_actionTypes.GET_CONFIRMATION_DATA, {...this.$route.query})
        .then(() => {
          if (this.emailConfirmationData.emailVerified || !this.emailConfirmationData.validLink) {
            this.$router.push({name: 'admin-login'})
          }
        })
    },

    computed: {
      ...mapState(['emailConfirmationData']),

      payloads () {
        return {
          uid: this.emailConfirmationData.uid,
          token: this.emailConfirmationData.token
        }
      },
    },

    methods: {

      verifyEmail () {
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.CONFIRM_EMAIL, this.payloads)
          .then((response) => {
            this.$notify({
              group: 'app',
              type: 'success',
              title: this.$t('notifications.title.success'),
              text: response.detail
            })
            this.showForm = false
            this.countDownTimer(this.countDown, this, { name: 'admin-login' })
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loader.hide()
          })
      }
    }
  }
</script>

<style>

</style>
