<template>
  <div>
    <div v-if="showForm">
      <h4 class="text-center mt-3">{{ $t('pages.login.user.reset_password_title') }}</h4>
      <b-form @submit.prevent="resetPassword" class="mt-3">
        <b-form-group
          id="email-group"
          :label="$t('auth.reset_password_email_field')"
          :invalid-feedback="invalidEmail"
          :state="stateEmail"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="email"
            type="email"
            :state="stateEmailInput"
            :placeholder="$t('auth.placeholder_email')"
          ></b-form-input>
        </b-form-group>
        <div class="form-actions">
          <b-button
            type="submit"
            variant="primary">
            {{ $t('auth.send_password_button') }}
          </b-button>
        </div>
      </b-form>
      <p class="text-center mt-2">
        <router-link :to="{name: 'user-login'}">
          {{ $t('auth.back_to_login') }}
        </router-link>
      </p>
    </div>
    <div v-else class="mt-5">
      <h3 class="text-center">
        {{ $t('notifications.text.auth.success_reset_password') }}
      </h3>
      <div class="form-actions">
        <b-button
          type="submit"
          :disabled="loading"
          @click="showForm = true"
          variant="primary">
          {{ $t('auth.reset_another') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { required, email } from 'vuelidate/lib/validators'

  export default {
    name: 'UserResetPassword',

    data () {
      return {
        email: null,
        showForm: true,
        loading: false
      }
    },

    computed: {
      ...mapState(['anonMarket']),
      payloads () {
        return {
          marketId: this.anonMarket.marketId,
          email: this.email
        }
      },
      stateEmailInput () {
        return this.$v.email.$error ? false : null
      },
      stateEmail () {
        return (!this.$v.email.$error)
      },
      invalidEmail () {
        if (this.$v.email.$error) {
          if (!this.$v.email.required)
            return this.$t('validation.required', { field: this.$t('auth.field.email') })
          else if (!this.$v.email.email)
            return this.$t('validation.email')
        }
        return null
      },
    },

    validations: {
      email: {
        required,
        email
      }
    },

    methods: {
      resetPassword () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.$v.$reset()
          this.loading = true
          this.loader = this.$loading.show(this.$_loaderOptions)
          this.$store.dispatch(this.$_actionTypes.RESET_PASSWORD, this.payloads)
            .then((response) => {
              this.$notify({
                group: 'app',
                type: 'success',
                title: this.$t('notifications.title.success'),
                text: response.detail
              })
              this.email = null
              this.showForm = false
            })
            .catch((error) => {
              this.$_notifyError(error, this)
            })
            .finally(() => {
              this.loading = false
              this.loader.hide()
            })
        }
      }
    }
  }
</script>

<style>

</style>
